import { Api } from '@/common/services/api-service'

const baseUrl = 'magazine/articles'

const getArticles = async (page = 1, limit = 25) => {
  try {
    const res = await Api.get(baseUrl, { page, limit })
    return res
  } catch (e) {
    return e
  }
}

const getArticle = async (slug) => {
  try {
    const res = await Api.get(encodeURI(`${baseUrl}/slug/${encodeURI(slug)}`))
    return res
  } catch (e) {
    return e
  }
}

const searchArticles = async (keyword, page = 1, limit = 25) => {
  try {
    const res = await Api.post(`${baseUrl}/search`, {
      keyword,
      pagination: {
        page,
        limit
      }
    })
    return res
  } catch (e) {
    return e
  }
}

export default {
  getArticles,
  getArticle,
  searchArticles
}
