<template>
  <v-card class="article-box">
    <router-link :to="{ name: 'article', params: { slug: article.slug }}">
      <v-img  v-if="article.assets.length"
            :src='imageUrl()'
            aspect-ratio="2"/>
    </router-link>

    <v-row no-gutters class="padding-sides-10">
      <v-col>
        <section class="padding-tb-20">
          <v-row>
            <v-col>
              <span :class="[authorFontSize(), 'd-flex']">
                <span itemprop="author">{{article.author}}</span> - <time datetime="">{{article.created_at | moment('MM/YYYY')}}</time>
              </span>
            </v-col>

            <v-col v-if="$vuetify.breakpoint.xsOnly">
              <v-row no-gutters justify=end>
                <share-network network="facebook"
                                :url="articleUrl('facebook')"
                                :title="article.title"
                                @open="trackShare('facebook')">
                  <v-icon class="icon-lg social-icon facebook-icon">mdi-facebook</v-icon>
                </share-network>
                &nbsp;
                <share-network network="whatsapp"
                                :url="articleUrl('whatsapp')"
                                :title="article.title"
                                @open="trackShare('whatsapp')">
                  <v-icon class="icon-lg social-icon whatsapp-icon">mdi-whatsapp</v-icon>
                </share-network>
                &nbsp;
                <copy-text :text="articleUrl('copy')" :tooltip="$t('estates.copy_link')" :sizes="{xs: 'md'}"/>
              </v-row>
            </v-col>
          </v-row>

          <v-row no-gutters align=center>
            <v-col>
              <h3 :class="titleFontSize()">
                <router-link :to="{ name: 'article', params: { slug: article.slug }}">{{article.title}}</router-link>
              </h3>
            </v-col>
          </v-row>
        </section>

        <section :class="previewFontSize()">
          {{contentPreview()}}
          <router-link :to="{ name: 'article', params: { slug: article.slug }}">{{$t('common.read_more')}}</router-link>
        </section>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import CopyText from '@/common/components/copy-text'

export default {

  name: 'ArticleBox',

  props: {
    article: {
      type: Object,
      required: true
    },

    imageWidth: {
      type: [Number, String],
      default: 1200
    },

    small: Boolean,
    medium: Boolean
  },

  components: {
    CopyText
  },

  data () {
    return {}
  },

  methods: {
    imageUrl () {
      return this.$cloudinary.url(this.article.assets[0].provider_id, { width: this.imageWidth, gravity: 'center', 'crop': 'fill' })
    },

    articleUrl (source) {
      return `${window.location.protocol}://${window.location.host}/magazine/${this.article.slug}?utm_source=${source}`
    },

    contentPreview () {
      const chars = this.small ? '100' : this.medium ? '250' : '400'
      return this.$options.filters.truncate(this.article.content, chars).replace(/(<([^>]+)>)/ig, ' ')
    },

    authorFontSize () {
      return this.small ? 'font-12' : this.medium ? 'font-13' : 'font-16'
    },

    titleFontSize () {
      return this.small ? 'font-18' : this.medium ? 'font-24' : 'font-36'
    },

    previewFontSize () {
      return this.small ? 'font-13' : this.medium ? 'font-15' : 'font-18'
    }
  }
}
</script>

<style lang="scss" scoped>

  .article-box {
    padding-bottom: 10px;
    overflow: hidden;

    h3 {
      color: var(--v-accent-darken2);

      a {
        color: inherit;
      }
    }
  }
</style>
